import React from 'react';

import Layout from '../../components/Layout';
import BlogRoll from '../../components/BlogRoll';

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <section id="hero" className="hero is-dark">
          <div className="hero-body">
            <div className="container">
              <div className="notification is-primary title-element">
                <h1 className="title is-1">Owl Path Blog</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <BlogRoll />
          </div>
        </section>
      </Layout>
    );
  }
}
